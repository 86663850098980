import { render, staticRenderFns } from "./red-flag.vue?vue&type=template&id=2e9ee06c"
import script from "./red-flag.vue?vue&type=script&lang=js"
export * from "./red-flag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RedFlagModal: require('/codebuild/output/src2108204121/src/web/components/red-flag-modal.vue').default})
